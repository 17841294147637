import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Біз туралы | Әділет серіктестерінің заң қызметтері туралы
			</title>
			<meta name={"description"} content={"Justice Partners заң қызметтері адалдық пен кәсібиліктің ең жоғары стандарттарын сақтай отырып, сапалы заңгерлік өкілдікті қолжетімді ету мақсатымен құрылған."} />
			<meta property={"og:title"} content={"Біз туралы | Әділет серіктестерінің заң қызметтері туралы"} />
			<meta property={"og:description"} content={"Justice Partners заң қызметтері адалдық пен кәсібиліктің ең жоғары стандарттарын сақтай отырып, сапалы заңгерлік өкілдікті қолжетімді ету мақсатымен құрылған."} />
			<meta property={"og:image"} content={"https://cenokist.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cenokist.live/img/7909222.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cenokist.live/img/7909222.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cenokist.live/img/7909222.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cenokist.live/img/7909222.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cenokist.live/img/7909222.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cenokist.live/img/7909222.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
						Біз туралы
					</Text>
					<Text margin="0px 0px 70px 0px" font="--lead" lg-margin="0px 0px 40px 0px" color="#626970">
						Justice Partners заң қызметтері адалдық пен кәсібиліктің ең жоғары стандарттарын сақтай отырып, сапалы заңгерлік өкілдікті қолжетімді ету мақсатымен құрылған. Біздің фирма қарапайым басынан әділеттілік пен клиенттердің қанағаттанушылығына деген міндеттеменің арқасында заң қоғамдастығындағы беделді ұйымға дейін өсті.
					</Text>
					<Image
						src="https://cenokist.live/img/6.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Біздің этика
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Әділет серіктестерінің негізінде тиімді заң қызметі жан-жақты білімді, эмпатикалық түсінушілікті және клиент пен адвокат арасындағы берік қарым-қатынасты біріктіреді деген сенім болып табылады. Біз заңды өкілдер ғана емес, өз клиенттеріміз үшін сенімді кеңесшілер мен сенімді қорғаушылар болуға ұмтыламыз.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Сот төрелігі серіктестерінің айрықша белгілері
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="#626970">
							Әртүрлі мамандықтар: Біздің командамыз заңның әртүрлі салаларында маманданған адвокаттардан тұрады, заңдық мәселелерге кешенді көзқарасты қамтамасыз етеді.
						</Text>
					</Box>
					<Box min-width="100px" margin="0px 0px 20px 0px" lg-margin="0px 0px 40px 0px">
						<Text margin="0px 0px 0px 0px" font="--base" color="#626970">
							Клиент-бірінші философия: Әрбір шешім мен стратегия клиенттің мүдделерін ескере отырып, жекелендірілген және тиімді құқықтық шешімдерді қамтамасыз ете отырып қабылданады.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-align-items="center"
				md-width="70%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				sm-margin="30px 0px 30px 0px"
				sm-width="100%"
				width="75%"
				lg-width="80%"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				sm-justify-content="center"
			>
				<Text
					margin="0px 0px 8px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					text-transform="uppercase"
					letter-spacing="2px"
				>
					Біздің заң саласындағы қорымыз
				</Text>
				<Text
					lg-text-align="left"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-font="normal 700 35px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-text-align="center"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
				>
					Әрбір іс заңдық ұшқырлық пен шынайы қамқорлықтың үйлесімі арқылы қаралатын Әділет серіктестерінің заң қызметтері этикасына сүңгіңіз.
				</Text>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-width="100%"
				sm-order="-1"
				justify-content="space-between"
				md-width="30%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="20%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					width="100%"
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					border-radius="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
				>
					<Image
						width="100%"
						top="0px"
						right={0}
						min-height="100%"
						bottom="auto"
						position="absolute"
						object-fit="cover"
						display="block"
						left={0}
						src="https://cenokist.live/img/7.jpg"
					/>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
			>
				<Box
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						width="100%"
						right={0}
						src="https://cenokist.live/img/8.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-11">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="start"
				lg-grid-template-columns="1fr"
			/>
			<Box min-width="10px" min-height="10px" margin="0px 50px 0px 0px" lg-margin="0px 0 50px 0px">
				<Text
					margin="0px 0px 30px 0px"
					font="normal 600 42px/1.2 --fontFamily-sans"
					letter-spacing="8px"
					white-space="nowrap"
					color="--primary"
					sm-white-space="normal"
				>
					Біз туралы көбірек біліңіз
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					overflow-wrap="normal"
					word-break="normal"
					text-overflow="clip"
					hyphens="none"
					lg-margin="0px 0px 35px 0px"
				>
					Қызметтеріміз, құқықтық мәселелерге деген көзқарасымыз туралы көбірек білу немесе кеңес беруді жоспарлау үшін:
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px 24px"
					sm-grid-template-columns="1fr"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 10px 0px" text-transform="uppercase" font="normal 600 18px/1.5 --fontFamily-sans" letter-spacing="2px">
							Қоғамдастықтың қатысуы
						</Text>
						<Text margin="0px 0px 0px 10px" font="--base">
							Әділет серіктестері қоғаммен байланыс бағдарламаларына және тегін қызметтерге қатысуға ниетті.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 10px 0px" text-transform="uppercase" font="normal 600 18px/1.5 --fontFamily-sans" letter-spacing="2px">
							Үздіксіз заңгерлік білім беру
						</Text>
						<Text margin="0px 0px 0px 10px" font="--base">
							Біз үздіксіз білім алуға және құқықтық жетістіктерден хабардар болуға, клиенттерімізге ақпараттандырылған және инновациялық құқықтық стратегиялардың пайдасын қамтамасыз етуге сенеміз.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px" display="flex" sm-flex-direction="column">
				<Image src="https://cenokist.live/img/9.jpg" display="block" margin="0px 35px 0px 0px" sm-margin="0px 0 30px 0px" />
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});